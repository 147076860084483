<template>
  <div class="wrapper">
    <div class="wrap" v-if="this.type == 999 || this.type == 1">
      <div class="box-header">
        <div class="name">医疗器械</div>
      </div>
      <div class="box-body">
        <!-- 商品列表 -->
        <div class="goods-wrapper">
          <div class="goods" v-for="(item,index) in list1" :key="index" @click="goodsDetail(item)">
            <img class="thumb" :src="item.imgs[0]">
            <div class="name elip">{{ item.name }}</div>
            <div class="desc elip">{{ item.xinghao }}</div>
          </div>
        </div>
      </div>
      <el-pagination layout="prev, pager, next , total" :total="5">
      </el-pagination>
    </div>
    <div class="wrap" v-if="this.type == 999  || this.type == 2">
      <div class="box-header">
        <div class="name">OTC药品</div>
      </div>
      <div class="box-body">
        <div class="goods-wrapper">
          <div class="goods" v-for="(item,index) in list2" :key="index" @click="goodsDetail(item)">
            <img class="thumb" :src="item.imgs[0]">
            <div class="name elip">{{ item.name }}</div>
            <div class="desc elip">{{ item.xinghao }}</div>
          </div>
        </div>
      </div>
      <el-pagination layout="prev, pager, next , total" :total="5">
      </el-pagination>
    </div>

    <img style="margin-top:20px;width:1224px;"
      src="http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgn_rB-gUoxJ_vlwYw5Ag4mwE.png.webp" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      goodsList: [
        {
          id: 1,
          type: 1,
          name: "雅斯医用雾化器",
          pinpai: "雅斯",
          qiye: "黄山市雅适医疗器械有限公司",
          zhuce: "皖械注准20162210271",
          xinghao: "YS-01",
          imgs: [
            "http://10249928.s21i.faiusr.com/2/ABUIABACGAAgnPbx-QUo2OL5pgUwoAY4oAY.jpg",
            "http://www.yuangousc.cn/upload//mainimg/ffe0bf1c-e53d-4136-90ef-a3ccda2179c1.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/c4414394-a835-4f02-ba76-3879b37073ae.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/fcdf447b-c20c-41e7-91c0-0effa2e6e44a.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/3f6b8c3c-7d24-401e-bf27-35b164126b62.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/6b96f473-5518-4627-a7dc-d1061c47ea6c.jpeg"
          ],
        },
        {
          id: 2,
          type: 1,
          name: "雅斯GLM-77 血糖分析仪套装（试纸100片装）",
          pinpai: "雅斯",
          qiye: "青岛厚美德生物科技有限公司",
          zhuce: "鲁械注准20152400304",
          xinghao: "GLM-77",
          imgs: [
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAg1NPB-gUoiMrmIzCCBjjdBQ.png",
            "http://www.yuangousc.cn/upload//mainimg/eda44ad0-c088-4462-8719-1fa867b50890.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/4e584a59-337f-4e07-8aa9-527480fd23fe.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/69c3fbf9-15ca-4cde-8aa3-13345916d4e3.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/b42d06f0-baa3-427d-a17f-a33bb4479166.jpeg",
          ],
        },
        {
          id: 3,
          type: 1,
          name: "格丽 医用压力绷带",
          pinpai: "格丽",
          qiye: "河北泰诺生物科技有限公司",
          zhuce: "冀辛械备20190006号",
          imgs: [
            "http://www.yuangousc.cn/upload//mainimg/c859f1a0-61a0-4d20-8123-a8ef3b829b4d.png",
            "http://www.yuangousc.cn/upload//mainimg/2415dc33-20a5-4cfd-aab5-76df18d4e239.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/8b52a6d5-fc98-466e-b1bb-013ce6033b95.jpeg",
          ],
        },
        {
          id: 4,
          type: 1,
          name: "爱可尔  子宫颈扩张器",
          pinpai: "爱可尔",
          qiye: "北京赛普九州科技发展有限公司",
          zhuce: "京械注准20172661015",
          imgs: [
            "http://www.yuangousc.cn/upload//mainimg/42b9b140-3a72-4f52-bf60-24f26b3c722a.png",
            "http://www.yuangousc.cn/upload//mainimg/a10302f7-9128-4285-885a-562c1961f9a4.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/3d400375-9b81-49f9-aa0e-2bd59997f69e.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/3e734ae9-3655-44de-ba2d-63473b173f6b.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/96e8f469-3cab-48e5-a176-07e524794f85.jpeg",
          ],
        },
        {
          id: 5,
          type: 1,
          name: "莎维亚二级长筒治疗型静脉曲张袜",
          pinpai: "莎维亚",
          qiye: "义乌市莎维亚袜业有限公司",
          zhuce: "浙械注准20162641101",
          xinghao: "均码",
          imgs: [
            "http://10249928.s21i.faiusr.com/2/ABUIABACGAAgjJ2o-QUoirO43AMwjQ44xA0.jpg",
            "http://www.yuangousc.cn/upload//mainimg/ac9067d0-e222-4175-9e2b-d0e04da047d5.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/a6749a82-99e6-4f96-992a-9c23f0477fa7.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/9907e568-3c3c-4cbc-8754-a07a30c767e1.jpeg",
            "http://www.yuangousc.cn/upload//mainimg/13c377bc-2070-449a-8cc0-922ea0610805.jpeg"
          ],
        },
        {
          id: 6,
          type: 2,
          name: "江中 乳酸菌素片64片",
          pinpai: "江中",
          qiye: "江中药业股份有限公司",
          zhuce: "国药准字H20059905",
          imgs: [
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgpd7f-wUoz52vlwMw7wU4hAY.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAg097f-wUoopDF4AMwxAU4kQc.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAg3uu9-gUo7PzjmwEw7wU47gY.png",
          ],
        },
        {
          id: 7,
          type: 2,
          name: "江中健胃消食片36片",
          pinpai: "江中",
          qiye: "江中药业股份有限公司",
          zhuce: "国药准字Z36021464",
          imgs: [
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAg7ua9-gUo8PGxvAEwoQY42QQ.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAg7ea9-gUohIyG3QIwogY4lQQ.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAg7ua9-gUol42F5QYw1AU4vAY.png",
          ],
        },
        {
          id: 8,
          type: 2,
          name: "江中牌健胃消食片32片",
          pinpai: "江中",
          qiye: "江中药业股份有限公司",
          zhuce: "国药准字Z20013220",
          imgs: [
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgzOm9-gUo3MOJogYwugU4mgM.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgzem9-gUoyf2WnwIwgQY4lAU.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgzem9-gUorfqJ-gIwmQU44wY.png",
          ],
        },
        {
          id: 9,
          type: 2,
          name: "京都念慈菴蜜炼川贝枇杷膏300ml",
          pinpai: "京都念慈菴",
          qiye: "京都念慈菴蜜炼川贝枇杷膏",
          zhuce: "批准文号: ZC20160006",
          imgs: [
            "http://10249928.s21i.faiusr.com/2/ABUIABACGAAglIXC-gUolMqwVjDCAzisAw.jpg",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgvd29-gUo2PHKpQQwlQY4mwY.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgvd29-gUo2PHKpQQwlQY4mwY.png",
          ],
        },
        {
          id: 10,
          type: 2,
          name: "仁和乳酸菌素片0.2g*40片/盒",
          pinpai: "仁和",
          qiye: "黑龙江百泰药业有限公司",
          zhuce: "国药准字H23023524",
          imgs: [
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgqu69-gUoqqaE5gUwpgY4xgU.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgqe69-gUoqtWyejDpBjjbBg.png",
            "http://10249928.s21i.faiusr.com/4/ABUIABAEGAAgqu69-gUosprMywcwoAY4ywY.png",
          ],
        },
      ],
      type:999,
      list1: [],
      list2: [],
    };
  },
  mounted() {
    let list = this.goodsList;
    let list1 = list.filter((item) => {
      return item.type == 1;
    });
    let list2 = list.filter((item) => {
      return item.type == 2;
    });
    this.list1 = list1;
    this.list2 = list2;
  },
  created(){
    if(this.$route.query.type){
      this.type = this.$route.query.type
      console.log(this.type)
    }else{
      this.type = 999
    }
    localStorage.setItem("goodsList", JSON.stringify(this.goodsList));
  },
  methods: {
    goodsDetail(item) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.el-pagination {
  background: #fff;
}
.wrapper {
  background-color: #f5f5f5;

  .wrap {
    width: 1226px;
    margin: 0 auto;

    .box-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        font-size: 22px;
        font-weight: 200;
        line-height: 58px;
        color: #333;
      }

      .btn-link {
        cursor: pointer;

        &:hover {
          .title {
            color: #ff6700;
          }

          .icon {
            background-color: #ff6700;
          }
        }

        .title {
          font-size: 16px;
          line-height: 58px;
          color: #424242;
          transition: all 0.4s;
        }

        .icon {
          width: 12px;
          height: 12px;
          padding: 4px;
          margin-left: 8px;
          border-radius: 16px;
          font-size: 12px;
          line-height: 12px;
          background-color: #b0b0b0;
          color: #fff;
          transition: all 0.4s;
        }
      }
    }

    .box-body {
      display: flex;

      .cover-wrapper {
        width: 234px;
        height: 614px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cover {
          width: 234px;
          cursor: pointer;
          transition: all 0.2s linear;

          &:hover {
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
            transform: translate3d(0, -2px, 0);
          }
        }
      }

      .goods-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background:#fff;
        .goods {
          width: 228px;
          height: 260px;
          padding: 20px 0;
          margin-left: 14px;
          margin-bottom: 14px;
          text-align: center;
          background-color: #ffffff;
          cursor: pointer;
          transition: all 0.2s linear;

          &:hover {
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
            transform: translate3d(0, -2px, 0);
          }

          .thumb {
            width: 160px;
            height: 160px;
            margin: 0 auto 18px;
          }

          .name {
            margin: 0 10px 2px;
            font-weight: 400;
            color: #333;
          }

          .desc {
            height: 18px;
            margin: 0 10px 10px;
            font-size: 12px;
            color: #b0b0b0;
          }

          .prices {
            display: flex;
            align-items: center;
            justify-content: center;

            .price-current {
              color: #ff6700;
            }

            .price-original {
              color: #b0b0b0;
              margin-left: 5px;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}
</style>
